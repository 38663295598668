<template>
  <v-container class="px-1 pt-0">
    <!-- Bild-karusellen -->
    <v-carousel cycle height="50vh" :show-arrows="false" hide-delimiters>
      <v-carousel-item v-for="(url, i) in urls" :key="i" :src="url" />
    </v-carousel>

    <!-- Snabb-navigering -->
    <v-container class="pa-3">
      <v-row class="px-1">
        <v-col
          cols="4"
          class="px-1 py-0"
          v-for="linkData in getLinkData"
          :key="linkData.path"
        >
          <!-- Fyrkantiga knappar för större skärmar -->
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            :to="linkData.path"
            block
            outlined
            class="primary--text"
            x-large
          >
            <v-icon left>{{ linkData.icon }}</v-icon>
            <textloader-component :textKey="linkData.path" />
          </v-btn>
          <!-- Runda knappar för små skärmar -->
          <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                :to="linkData.path"
                fab
                class="btn--navigation primary--text"
              >
                <v-icon>{{ linkData.icon }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-center pt-0">
              <textloader-component :textKey="linkData.path" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            outlined
            class="primary--text"
            x-large
            href="http://eepurl.com/gi8Xir"
            target="_blank"
          >
            <v-icon left>mdi-email</v-icon>
            FUTFs nyhetsbrev!
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-divider />

    <!-- Flöde -->
    <v-container class="pt-0 px-2">
      <v-row>
        <v-col :cols="12" v-for="(post, i) in posts" :key="i">
          <newscard
            :id="post.id"
            :title="post.title"
            :title_en="post.title_en"
            :subtitle="post.subtitle"
            :subtitle_en="post.subtitle_en"
            :text="post.text"
            :text_en="post.text_en"
            :category="post.category"
            :publishedtime="post.publishedtime"
            :eventtime="post.eventtime"
            :location="post.location"
            :location_en="post.location_en"
            :form="post.form"
            :image="post.image"
          ></newscard>
        </v-col>
        <v-col
          v-if="
            $vuetify.breakpoint.xsOnly ||
            $vuetify.breakpoint.smOnly ||
            $vuetify.breakpoint.mdOnly
          "
          cols="12"
        >
          <sponsorlist />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import newscard from '@/components/Newscard.vue'
import sponsorlist from '@/components/SponsorComponent.vue'
import navdata from '@/assets/navdata.json'
import TextloaderComponent from '@/components/TextloaderComponent'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    newscard,
    sponsorlist,
    TextloaderComponent
  },
  data: () => {
    return {
      // Lista med bild-länkar
      urls: [
        require('@/assets/styrelsebilder/styrelsen.jpg'),
        require('@/assets/Home/AngstromFramsida.jpeg'),
        'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
      ],
      posts: [],
      // Lista med inlägg till nyhetsflödet
      // Lista med sidor att ha snabbnavigering till
      quacklings: [
        // (quick-links)
        '/kalender',
        '/schema',
        '/engageradig'
      ]
    }
  },
  computed: {
    getLinkData: function () {
      // Read navigation list form 'pageinfo.json'
      const data = []

      // Find target page, and insert correct path and icon
      for (const linkname of this.quacklings) {
        const page = navdata.pageinfo.find((p) => p.path === linkname)
        if (page !== undefined) {
          data.push({ path: page.path, icon: page.icon })
        }
      }
      return data
    }
  },
  created () {
    // console.log('Attempting to fetch events')
    // this.posts = require('@/../db.json').happenings - innan api
    axios
      .get('https://api.futf.se/api/event/read.php')
      .then((result) => {
        // Det som är senast tillagt i DB:n ska visas först
        // console.log(result.data)
        result.data.reverse()

        // Ändra url beroende på om bild är uppladdad eller om länk
        for (let i = 0; i < result.data.length; i++) {
          const data = result.data[i]
          let image
          if (data.image.split('.').length === 2) {
            const imgname = data.image
            // "../assets/startsida.jpg"
            // `@/assets/${imgname}`
            image = {
              url: 'https://api.futf.se/api/images/' + imgname,
              bigimg: data.bigimg
            }
          } else {
            image = {
              url: data.image,
              bigimg: data.bigimg
            }
          }

          // TODO: should be handled in AddEvent.vue
          data.id = parseInt(data.id)
          // console.log(data.form_id)
          // if (!isNaN(parseInt(data.form_id))) {
          //   data.form_id = parseInt(data.form_id)
          // }
          const form = {
            id: data.form_id, // pga newcars kollar om form.id == 0, inte "0"
            opentime: data.form_opentime,
            closetime: data.form_closetime
          }
          data.image = image
          data.form = form
          result.data[i] = data
        }
        this.posts = result.data
        // console.log(this.posts)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
</script>
